//
// validator.js
// Copyright 2020 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';
import _ from 'lodash';
import Log from 'loglevel';

export class ValidationError extends Error {
  constructor(validationErrors) {
    super('Validation failed.');
    this._validationErrors = validationErrors;
  }

  get validationErrors() { 
    return this._validationErrors; 
  }
}

export default class Validator { // TODO: EXTENDS SERVICE
    constructor(schema, messages) {
        this.schema = schema;
        this.messages = messages;
    }

    init() {
        this.ajv = new Ajv({allErrors: true, jsonPointers: true, nullable: true});
        AjvErrors(this.ajv);
        this.checkValid = this.ajv.compile(this.schema);
    }

    async validate(params) {
        return new Promise((resolve, reject) => {
            try {
                if (!this.ajv) {
                    throw new Error('Validator has not been initialized');
                }

                Log.debug('Validating params: ', params);
                const validationErrors = Object.keys(
                    _.pickBy(params, (value, key) => !this.checkValid({ [key]: value }))
                );

                if (!_.isEmpty(validationErrors)) {
                    Log.debug('Validation errors: ', validationErrors);
                    throw new ValidationError(validationErrors);
                }

                resolve(true);
            } catch(error) {
                reject(error);
            }
        })
    }
}