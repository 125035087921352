//
// booking.js
// Copyright 2020 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from '../cmComponent';
import CMForm from '../cmForm';
import _ from 'lodash';
import Log from 'loglevel';
import Page from '../page';
import React from "react";
import { Icon, Header, Form, Grid, Label } from 'semantic-ui-react';
import { ValidationError } from '../validator';

export default class BookingPage extends CMComponent {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        studentName: { value: null, valid: true, message: null },
        parentName: { value: null, valid: true, message: null },
        email: { value: null, valid: true, message: null },
        services: { value: (props.selectedService? [props.selectedService] : []), valid: true, message: null },
        comments: { value: null, valid: true, message: null },
        marketing: { value: false, valid: true, message: null },
        policy: { value: false, valid: true, message: null }
      },
      formState: 'input',
      formComplete: false,
      recaptcha: null
    };

    this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onRecaptcha = this.onRecaptcha.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  get formDisabled() { return this.state.formComplete; }

  handleFormFieldChange(e, data) {
    if (!data || !data.id) {
      Log.warn('received handleFormFieldChange event with no id');
      return;
    } else {
      Log.trace(data);
    }

    const id = data.id
    let value = data.value || null;
    if (data.id === 'policy' || data.id === 'marketing') {
      value = data.checked;
    }
      
    this.setState((state) => {
      let newState = state;
      newState.formFields[id] = { value: value, valid: true, message: null };
      return newState;
    });
  }

  async onFormSubmit() {
    try {
      if (await this.validateForm()) {
        await this.postForm();
      }
    } catch (error) {
      this.setState({ formComplete: true, formState: 'error' });
    }
  }

  async validateForm() {
    // TODO: MOVE TO VALIDATOR
    const validator = this.props.validator;
    try {
      const values = Object.fromEntries(
        Object.entries(this.state.formFields).map(([name, desc]) => {
          return [name, desc.value];
        })
      );

      await validator.validate(values);
      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        const fields = Object.fromEntries(
          Object.entries(this.state.formFields).map(([name, desc]) => {
            if (error.validationErrors.includes(name)) {
              return [name, { value: desc.value, valid: false, message: validator.messages[name] }];
            } else {
              return [name, { value: desc.value, valid: true, message: null }];
            }
          })
        );

        console.log(this.state.formFields);
        this.setState({ formFields: fields, formState: 'invalid' });
      } else {
        Log.error(error);
      }

      return false;
    }
  }

  async postForm() {
    const fields = Object.fromEntries(
      Object.entries(this.state.formFields).map(([name, desc]) => {
        return [name, desc.value];
      })
    );
    const params = _.merge(fields, { recaptcha: this.state.recaptcha });
    const response = await fetch(this.props.formAction, {
      method: 'post',
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' }
    });

    const data = await response.json();
    if (data) {
      console.assert(data.studentName === fields.studentName)
      console.assert(data.email === fields.email)
      console.assert(data.comments === fields.comments)
      console.assert(_.isEmpty(data.services.filter(x => !fields.services.includes(x))));
    }

    this.setState({ formComplete: true, formState: 'success' });
  }

  onRecaptcha(token) {
    this.setState({ recaptcha: token });
  }

  render() {
    const options = this.props.services.map(s => ({
      key: `service-${s.id}`,
      text: `${s.name}`,
      value: s.id,
      content: (
        <Header id={s.id} content={s.name} subheader={`$${s.price} ${s.terms}`} />
      )
    }));

    return (
       <Page 
        id='booking' 
        nav='booking' 
        title='Booking' 
			  showLogo={this.props.showLogo} 
        setActive={this.props.setActive}
      >
          <Header as='h2' dividing>Reserve Your Spot</Header>
          <Grid columns={2}>
            <Grid.Column>
              <p>
                Thank you for your interest in booking with us! Once we confirm availability, we will send you an email
                containing member login information where you may complete your registration and payment. If your requested
                class is full, we will add you to the wait list. Please let us know if you don't see a response by tomorrow
                    night <Icon name='smile outline' /></p>
              <p>Thanks and we look forward to meeting you soon!</p>
            </Grid.Column>
            <Grid.Column>
              <CMForm
                state={this.state.formState}
                complete={this.state.formComplete}
                fields={this.state.formFields}
                recaptchaSiteKey={this.props.recaptchaSiteKey}
                onRecaptcha={this.onRecaptcha}
                onSubmit={this.onFormSubmit}
                successHeader='Booking Request Sent'
                successContent="Success! We received your booking request and will contact you soon. If you don't hear from us by tomorrow night, please send an email to info@cecalamusic.com."
                errorHeader='Submission Failed'
                errorContent="Oh no! We were unable to send your booking request. Please email us at info@cecalamusic.com to request your booking."
              >
              <Grid columns='equal' verticalAlign='bottom' style={{ marginBottom: '1rem'}}>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Grid.Column>
                    <Form.Input
                      id='studentName'
                      label='Student Name'
                      type='text'
                      required
                      disabled={this.formDisabled}
                      onChange={this.handleFormFieldChange}
                      error={CMForm.getError(this.state.formFields.studentName)}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      id='parentName'
                      label='Parent Name (if student is a minor)'
                      type='text'
                      disabled={this.formDisabled}
                      onChange={this.handleFormFieldChange}
                      error={CMForm.getError(this.state.formFields.parentName)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

                <Form.Input
                  id='email'
                  label='Email'
                  type='email'
                  required
                  disabled={this.formDisabled}
                  onChange={this.handleFormFieldChange}
                  error={CMForm.getError(this.state.formFields.email)}
                />

                <Form.Dropdown
                  id='services'
                  label='Select Your Lessons (multiple allowed)'
                  required
                  disabled={this.formDisabled}
                  fluid 
                  multiple 
                  selection
                  options={options}
                  value={this.state.formFields.services.value}
                  onChange={this.handleFormFieldChange}
                  onClick={this.handleFormFieldChange}
                  error={CMForm.getError(this.state.formFields.services)}
                />

                <Form.TextArea
                  id='comments'
                  label='Questions or Comments'
                  disabled={this.formDisabled}
                  onChange={this.handleFormFieldChange}
                  error={CMForm.getError(this.state.formFields.comments)}
                />
              
              <Label
                as='a'
                href='policy'
                target='_blank'
                color='red'
                ribbon
                style={{ marginBottom: '1rem' }}
              >
                Click here to view the policy
              </Label>
              <Form.Checkbox
                id='policy'
                label="I agree to the Cecala Music Studio Policy."
                checked={this.state.formFields.policy.value}
                disabled={this.formDisabled}
                onChange={this.handleFormFieldChange}
                error={CMForm.getError(this.state.formFields.policy)}
              />
              
              <Form.Checkbox
                id='marketing'
                label="I want to receive news, announcements and offers."
                checked={this.state.formFields.marketing.value}
                disabled={this.formDisabled}
                onChange={this.handleFormFieldChange}
                error={CMForm.getError(this.state.formFields.marketing)}
              />

            </CMForm>
            </Grid.Column>
          </Grid>
      </Page>
    );
  }
}