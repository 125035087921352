//
// cmForm.jsx
// Copyright 2020 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from './cmComponent';
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Container, Form, Message } from 'semantic-ui-react';

export default class CMForm extends CMComponent {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static getError(field) {
        return (field.valid ? null : { content: field.message, pointing: 'below' });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.props.onSubmit) {
            return this.props.onSubmit();
        }
    }

    get hasInvalid() {
        return this.props.state === 'invalid';
    }

    get hasError() {
        return this.props.state === 'error';
    }

    get hasSuccess() {
        return this.props.state === 'success';
    }

    get isComplete() {
        return this.props.complete;
    }

    get isDisabled() {
        return this.isComplete || this.props.disabled;
    }

    render() {
        return (
            <Form
                success={this.hasSuccess}
                warning={this.hasInvalid}
                error={this.hasError}
                onSubmit={this.handleSubmit}
            >
                <Message
                    success
                    hidden={!this.hasSuccess}
                    header={this.props.successHeader}
                    content={this.props.successContent}
                />
                <Message
                    warning
                    hidden={!this.hasInvalid}
                    header='Invalid Request'
                    content="Invalid request. Please correct the errors below."
                />
                <Message
                    error
                    hidden={!this.hasError}
                    header={this.props.errorHeader}
                    content={this.props.errorContent}
                />
                <div style={this.isDisabled? { display: 'none' } : null}>
                    {this.props.children}
                    <Container>
                        <Form.Field disabled={this.isDisabled}>
                            <ReCAPTCHA
                                sitekey={this.props.recaptchaSiteKey}
                                onChange={this.props.onRecaptcha}
                            />
                        </Form.Field>
                        <Button
                            primary
                            type='submit'
                            disabled={this.isDisabled}
                        >Submit</Button>
                    </Container>
                </div>
            </Form>
        );
    }
}