//
// lessons.jsx
// Copyright 2020 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from '../cmComponent';
import { ProductCard } from '../cards';
import Page, { Anchor } from '../page';
import React from "react";
import { Card, CardGroup, Header, Icon, Grid, Label, Segment, Table } from 'semantic-ui-react';

export default class LessonsPage extends CMComponent {
    render() {
        return (
            <Page
                id='lessons'
                nav='lessons'
                title='Voice Lessons'
                subtitle={`
                    Learning to sing can often seem complicated and challenging.
                    We specialize in simplifying that process and personalizing it to each individual's needs no matter their
                    age or experience level. Top it off with fun and enthusiasm and you gain a recipe for success.
                `}
                setActive={this.props.setActive}
            >
                <Anchor id='classes'>
                    <Header as='h2' dividing color='purple'>Online Summer Camps</Header>
                </Anchor>
                <Segment basic>
                    <Grid container >
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <p>
                                    Singing is always better when you're having FUN doing it. We are excited to announce
                                    our FUNdamentals classes with a summer-camp style of learning and adventure.
                                    Junior classes comprise ages 12 and under, and Teens are 13 to 18.
                                </p>
                                <Header as='h4'>Music FUNdamentals</Header>
                                <p>
                                    Quickly improve your ability to sight-read music and train your musical ear. From
                                    auditions to recording sessions to performing in ensembles, your level of enjoyment
                                    will soar when you don't have to rely on others to simply learn your music and hear
                                    your part. Be ready to claim your sight-reading independence! (For the study of <em>any </em>
                                    instrument, not just voice.)
                                </p>
                                <Header as='h4'>Singing FUNdamentals</Header>
                                <p>
                                    Packed with practical tools and fun projects that will challenge you and build a
                                    solid foundation learning the <em>"whys"</em> and <em>"hows"</em> of singing. We'll learn how the
                                    vocal anatomy works and how <strong>not</strong> to get in its way, as well as the "ins" and "outs"
                                    of performing and auditioning, with a group performance at the end.
                                </p>
                                <p className='notice'>
                                    <i>Just a thought</i>... For kids who are missing their friends and relatives during this
                                    pandemic and would like to re-connect over the summer, here's your chance: you can attend
                                    a 2-week "summer camp" together.
                                </p>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <CardGroup centered itemsPerRow={2} stackable>
                                    <ProductCard
                                        id='musicfundamentals'
                                        productId='e5b29605-4ffc-42e8-a271-264ee628cd8e'
                                        selectService={this.props.selectService}
                                        title='Music FUNdamentals'
                                        src='/images/music_fundamentals.svg'
                                        offers={[{ price: 200, terms: '2 weeks' }]}
                                        act='Book Now'
                                    >
                                        <Card.Meta>
                                            <p>Jul 6th - Jul 18th</p>
                                            <p>10:30 AM - 12:00 PM MDT</p>
                                            <p>Juniors: Mon, Wed, Fri</p>
                                            <p>Teens: Tue, Thur, Sat</p>
                                        </Card.Meta>
                                        <Card.Description>
                                            <p>Have fun building confidence in reading notes and rhythm while training your musical ear.</p>
                                        </Card.Description>
                                    </ProductCard>
                                    <ProductCard
                                        id='singingfundamentals'
                                        productId='78aef2bd-c25b-4937-bc82-2e2cc741ec23'
                                        selectService={this.props.selectService}
                                        title='Singing FUNdamentals'
                                        src='/images/singing_fundamentals.svg'
                                        offers={[{ price: 200, terms: '2 weeks' }]}
                                        act='Book Now'
                                    >
                                        <Card.Meta>
                                            <p>Jul 27th - Aug 8th</p>
                                            <p>10:30 AM - 12:00 PM MDT</p>
                                            <p>Juniors: Mon, Wed, Fri</p>
                                            <p>Teens: Tues, Thur, Sat</p>
                                        </Card.Meta>
                                        <Card.Description>
                                            <p>
                                                Learn and create together while discovering the tools of great singing and performance.
                                            </p>
                                        </Card.Description>
                                    </ProductCard>
                                </CardGroup>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Anchor id='workshops'>
                    <Header as='h2' dividing color='purple'>Workshops</Header>
                </Anchor>
                <Segment basic>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <CardGroup centered itemsPerRow={2} stackable>
                                    <ProductCard
                                        id='practice'
                                        productId='a01f90b9-f4c3-43c4-94aa-f7441b322d77'
                                        selectService={this.props.selectService}
                                        title='Practically Perfect Practice'
                                        src='/images/perfect_practice.svg'
                                        offers={[{ price: 35, terms: 'or free with lessons' }]}
                                        act='Book Now'
                                    >
                                        <Card.Meta>
                                            <p>Saturday July 11th</p>
                                            <p>1:00 PM - 2:00 PM MDT</p>
                                        </Card.Meta>
                                        <Card.Description>
                                            <p>For parents who want to help their musicians practice more effectively</p>
                                            <p>Maximize the value of your music lessons</p>
                                        </Card.Description>
                                    </ProductCard>

                                    <ProductCard
                                        id='nonsingers'
                                        productId='752aa7c8-5df2-415d-b800-3d4e621c412a'
                                        selectService={this.props.selectService}
                                        title='Singing for Non-Singers'
                                        src='/images/non_singers.svg'
                                        offers={[{ price: 100 }]}
                                        act='Book Now'
                                    >
                                        <Card.Meta>
                                            <p>Saturday Aug 1st</p>
                                            <p>1:00 PM - 4:00 PM MDT</p>
                                        </Card.Meta>
                                        <Card.Description>
                                            <p>For adults who aspire to carry a tune but never learned how</p>
                                            <p>Gain confidence, improve breathing, relieve stress, and be heard</p>
                                        </Card.Description>
                                    </ProductCard>

                                </CardGroup>
                            </Grid.Column>
                            <Grid.Column>
                                <Header as='h4'>Practically-Perfect Practice: Getting the most value from your music lessons</Header>
                                <p>
                                    Quality practice is crucial in determining a positive return on investment in music lessons.
                                    Learn how to consistently support your student in developing and maintaining healthy practice
                                    habits in a positive and motivating way. We encourage you to sign up immediately to start 
                                    utilizing these effective tools as soon as possible.
                                </p>
                                <p className='notice'>
                                    We request that <strong>all</strong> parents of students enrolled in our studio take
                                    this workshop at least once, at no additional cost. It's our gift to you for
                                    allowing us the opportunity to do what we love and help these great kids <em>love </em> what they do! 
                                    (Our students will also learn these tools in their lessons and classes.)
                                </p>
                                <Header as='h4'>Singing for Non-Singers</Header>
                                <p>
                                    People miss out on so many benefits of singing simply because they never learned how,
                                    or worse, were told they shouldn't sing because they weren't any good. Has that ever happened to you?
                                    Far too many people have told us they <i>can't</i> sing, and we're here to say that it simply <em>isn't </em> true.
                                    No more excuses ... if you can speak, you <strong>can</strong> sing. Get ready to be
                                    surprised and let's have a blast! (Prerequisite for "non-singers" to take <i>Singing Fundamentals for Adults</i> in the Fall)
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Anchor id='private'>
                    <Header as='h2' dividing color='purple'>Private Lessons</Header>
                </Anchor>
                <Segment basic>
                    <Grid centered width={12}>
                        <Grid.Row textAlign='left' style={{ padding: 0}}>
                            <Grid.Column textAlign='left' width={12}>
                                <Segment basic>
                                    <p>
                                        Anne works with kids who dream of becoming professional performers, and kids who just love
                                        to sing as a hobby. She works with busy soccer moms and dads whose careers have pushed music
                                        out of their lives, or who never got the chance to take lessons. She also works with seniors who
                                        are lifelong musicians dealing with health issues and want to use singing to get back into shape
                                        again.
                                        </p>
                                    <p>
                                        Everyone has their own unique and <em>important </em> reasons for wanting to study singing, and no matter how much or
                                        how little they know, Anne <em>loves </em> to jump in and help them get from wherever they're at to where they'd like
                                        to go. She's also <em>passionate </em> about finding what it takes to keep the joy of singing alive along each of their journeys.
                                    </p>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Segment basic>
                        <Grid centered width={12}>
                            <Grid.Row>
                                <Grid.Column width={12} textAlign='left'>
                                    <Label color='teal'>
                                        <Icon name='dollar' />
                                        Prices shown are per lesson and include a $20-per-month discount when purchased as a subscription.
                                    </Label>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={12}>
                                    <CardGroup centered itemsPerRow={3} stackable>
                                        <ProductCard
                                            id='individuallessons'
                                            productId='76a5f26a-3015-4abc-97b4-99b5315c96c7'
                                            selectService={this.props.selectService}
                                            title='Individual Voice&nbsp;Lessons'
                                            src='/images/individual_lessons.svg'
                                            offers={[{ price: 40, terms: '12 and under' }, { price: 60, terms: '13 and up' }]}
                                            act='Book Now'
                                        >
                                            <Label corner='left' color='teal'><Icon name='dollar' /></Label>
                                            <Card.Meta>
                                                <p>Children: 30 minutes</p>
                                            </Card.Meta>
                                            <Card.Description style={{ paddingBottom: '1rem' }}>
                                                <p>Develop healthy habits, build confidence, and have fun</p>
                                            </Card.Description>
                                            <Card.Meta>
                                                <p>Teens & Adults: 45 minutes</p>
                                            </Card.Meta>
                                            <Card.Description>
                                                <p>Develop performance skills, expand and smooth vocal range</p>
                                            </Card.Description>
                                        </ProductCard>
                                        <ProductCard
                                            id='familylessons'
                                            productId='c3e731a2-3ce6-4ca6-a240-e2fcd5556a79'
                                            selectService={this.props.selectService}
                                            title='Family Voice&nbsp;Lessons'
                                            src='/images/family_lessons.svg'
                                            offers={[{ price: 75, terms: '2 students' }, { price: 115, terms: '3 students' }]}
                                            act='Book Now'
                                        >
                                            <Label corner='left' color='teal' icon='dollar'></Label>
                                            <Card.Meta>
                                                <p>2 students: 45 minutes</p>
                                                <p>3 students: 1 hour</p>
                                            </Card.Meta>
                                            <Card.Description>
                                                <p>Build confidence through group interaction</p>
                                                <p>Group warmups followed by individual coaching for each student</p>
                                            </Card.Description>
                                        </ProductCard>
                                        <ProductCard
                                            id='coaching'
                                            productId='fd8e12d8-ab54-47e4-a9e4-b2728d308aee'
                                            selectService={this.props.selectService}
                                            title='Audition &amp; Performance Coaching'
                                            src='/images/coaching.svg'
                                            offers={[{ price: 100, terms: 'per hour' }]}
                                            act='Book Now'
                                        >
                                            <Card.Description>
                                                <p>Prepare for an audition or a performance</p>
                                                <p>Handle performance anxiety</p>
                                                <p>Select appropriate music</p>
                                                <p>Improve stage presence, energy, and delivery</p>
                                            </Card.Description>
                                        </ProductCard>
                                    </CardGroup>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <Segment basic>
                        <Grid centered>
                            <Grid.Column width={12}>
                                <Table
                                    color='purple'
                                    columns={4}
                                    verticalAlign='top'
                                    basic='very'
                                    compact='very'
                                    className='lesson-features'
                                >
                                    <Table.Body>
                                        <Table.Row width={12} verticalAlign='top'>
                                            <Table.Cell>
                                                <p>
                                                    <span style={{ display: 'block' }}>All ages welcome</span>
                                                    <span>(Typically 5 and up)</span>
                                                </p>
                                            </Table.Cell>
                                            <Table.Cell><p>Science-based natural singing techniques</p></Table.Cell>
                                            <Table.Cell><p>Stage presence and performance skills</p></Table.Cell>
                                            <Table.Cell><p>Build confidence</p></Table.Cell>
                                        </Table.Row>
                                        <Table.Row width={12} verticalAlign='top'>
                                            <Table.Cell>
                                                <p>
                                                    <span style={{ display: 'block' }}>All styles of music</span>
                                                    <span>(focus on favorites)</span>
                                                </p>
                                             </Table.Cell>
                                            <Table.Cell><p>Beginning to advanced</p></Table.Cell>
                                            <Table.Cell>
												<p>
													<span style={{ display: 'block' }}>Personalized solutions</span>
													<span>(head voice, "breaks")</span>
                                                </p>
                                            </Table.Cell>
                                            <Table.Cell><p>Audition preparation</p></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Segment>
                <Anchor id='ensembles'>
                    <Header as='h2' dividing color='purple'>Performance Ensembles</Header>
                </Anchor>
                <Segment basic>
                    <Grid container >
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <p>
                                    Focusing mostly on contemporary styles like pop, rock, gospel, a capella, and more, 
                                    these are not your typical choral ensembles. If you love singing ambitious, energetic, 
                                    and meaningful music while building friendships along the way, then this is the place for you! 
                                    Share your love of singing with others, participate in professionally produced events, and 
                                    connect with audiences everywhere &mdash; online and in person. 
                                </p>
                                <p>
                                    Different levels of experience are welcome, but please expect high standards of artistry and 
                                    musicianship, to learn and grow as accomplished performers, and to serve the purpose of each 
                                    event. The majority of our music will include 4 to 8-part harmonies and several solos, so 
                                    dedication and drive are a must to thrive in these groups. 
                                </p>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <CardGroup centered itemsPerRow={2} stackable>
                                    <ProductCard
                                        id='childrens-choir'
                                        productId='ddc3b0b6-3448-4aba-8370-2af4df31ab35'
                                        title="Children's Ensemble"
                                        src='/images/childrens_choir.svg'
                                        act='Learn More'
                                        href='home#news'
                                    >
                                        <Card.Meta>
                                            <p>Ages 5 to 18</p>
                                        </Card.Meta>
                                        <Card.Description>
                                            <p>Discover your potential while creating great music together!</p>
                                            <p>
                                                Belong to a group with a "family" dynamic, where all ages support and 
                                                encourage one another.
                                            </p>
                                        </Card.Description>
                                    </ProductCard>
                                    <ProductCard
                                        id='adult-choir'
                                        productId='91753f68-6e38-4450-a9c5-ba9b80c15835'
                                        title='Adult Ensemble'
                                        src='/images/adult_choir.svg'
                                        act='Learn More'
                                        href='home#news'
                                    >
                                        <Card.Meta>
                                            <p>Ages 19 and Up</p>
                                        </Card.Meta>
                                        <Card.Description>
                                            <p>Music is lifelong so continue your journey with us!</p>
                                            <p>
                                                Sing complex harmonies and expressive melodies with others 
                                                who are just as passionate about music as you are. 
                                            </p>
                                        </Card.Description>
                                    </ProductCard>
                                </CardGroup>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Page>
        );
    }
}
