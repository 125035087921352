//
// nav.jsx
// Copyright 2020 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import React from "react";
import { Icon, Menu } from 'semantic-ui-react'
import './css/nav.css'

export class NavBar extends React.Component {
  render() {

    // Render in two layers:
    // 1) A fixed layer is attached to the top of the viewport
    // 2) A movable shim layer displaces content to below the fixed portion
    // The combined effect is that the menu sits at the top of the screen,
    // and the screen contents will scroll underneath the menu
    return (
      <div className='nav'>
        <div className='nav-fixed'>
          <Menu text inverted compact size='large'>
              {this.props.children}
          </Menu>
        </div>
        <div className='nav-shim'></div>
      </div>
    )
  }
}

export class NavButton extends React.Component {
  render() {
    return (
      <Menu.Item
        href={this.props.href}
        name={this.props.nav}
        active={this.props.active}
      >
        <div className='nav-item'>
          <div className='label'>
            <Icon name={this.props.iconName} />
            {this.props.label}
          </div>
          <div className='indicator'></div>
        </div>
      </Menu.Item>
    );
  }
}