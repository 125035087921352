import App from './App.jsx';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-less/semantic.less'
import './css/index.css';

const VERSION = require('../package.json').version;
console.log(`cm-website version: ${VERSION}`)

const API_URL = process.env.REACT_APP_API_URL;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const MUSIC_PORTAL_URL = process.env.REACT_APP_MUSIC_PORTAL_URL;

ReactDOM.render(
  <App 
    apiUrl={API_URL} 
    recaptchaSiteKey={RECAPTCHA_SITE_KEY} 
    musicPortalUrl={MUSIC_PORTAL_URL}
  />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();