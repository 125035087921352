//
// contact.js
// Copyright 2020 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import CMComponent from '../cmComponent';
import CMForm from '../cmForm';
import _ from 'lodash';
import Log from 'loglevel';
import Page from '../page';
import React from "react";
import { Icon, Form, Header, Grid } from 'semantic-ui-react';
import { ValidationError } from '../validator';

// TODO - STORE THE CONTACT INFO IN DB LEADS TABLE FOR MARKETING PURPOSES, AND CHECK PRIVACY POLICY
// TODO - DRY CODE WITH BOOKING

export default class ContactPage extends CMComponent {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        name: { value: null, valid: true, message: null },
        email: { value: null, valid: true, message: null },
        subject: { value: null, valid: true, message: null },
        comments: { value: null, valid: true, message: null },
        marketing: { value: false, valid: true, message: null }
      },
      formState: 'input',
      formComplete: false,
      recaptcha: null
    }
    this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onRecaptcha = this.onRecaptcha.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  get formDisabled() { return this.state.formComplete; }

  handleFormFieldChange(e, data) {
    if (!data || !data.id) {
      Log.warn('received handleFormFieldChange event with no id');
      return;
    } else {
      Log.trace(data);
    }

    const id = data.id
    let value = data.value || null;
    if (data.id === 'marketing') {
      value = data.checked;
    }

    Log.trace(`Updating form field ${id}: ${value}`);
    this.setState((state) => _.merge(state, { formFields: { [id]: { value: value } } }));
  }

  async onFormSubmit() {
    try {
      if (await this.validateForm()) {
        await this.postForm();
      }
    } catch (error) {
      this.setState({ formComplete: true, formState: 'error' });
    }
  }

  async validateForm() {
    // TODO: MOVE TO VALIDATOR
    const validator = this.props.validator;
    try {
      const values = Object.fromEntries(
        Object.entries(this.state.formFields).map(([name, desc]) => {
          return [name, desc.value];
        })
      );

      await validator.validate(values);
      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        const fields = Object.fromEntries(
          Object.entries(this.state.formFields).map(([name, desc]) => {
            if (error.validationErrors.includes(name)) {
              return [name, { value: desc.value, valid: false, message: validator.messages[name] }];
            } else {
              return [name, { value: desc.value, valid: true, message: null }];
            }
          })
        );

        console.log(this.state.formFields);
        this.setState({ formFields: fields, formState: 'invalid' });
      } else {
        Log.error(error);
      }

      return false;
    }
  }

  async postForm() {
    const fields = Object.fromEntries(
      Object.entries(this.state.formFields).map(([name, desc]) => {
        return [name, desc.value];
      })
    );
    const params = _.merge(fields, { recaptcha: this.state.recaptcha });
    const response = await fetch(this.props.formAction, {
      method: 'post',
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' }
    });

    const data = await response.json();
    if (data) {
      //console.assert(data.studentName === fields.studentName)
      //console.assert(data.email === fields.email)
      //console.assert(data.comments === fields.comments)
      //console.assert(_.isEmpty(data.services.filter(x => !fields.services.includes(x))));
    }

    this.setState({ formComplete: true, formState: 'success' });
  }

  onRecaptcha(token) {
    this.setState({ recaptcha: token });
  }

  render() {
    return (
      <Page
        id='contact'
        nav='contact'
        title='Contact Us'
        showLogo={this.props.showLogo}
        setActive={this.props.setActive}
      >
        <Header as='h2' dividing>Tell Us Your Thoughts</Header>
        <Grid columns={2}>
          <Grid.Column>
            <p>
              We want to serve you in the best way possible. YOU MATTER to us and we know we aren't perfect,
              so we would love to know your thoughts and experiences with us. Please feel free to ask any questions or
                leave us any feedback or suggestions (positive feedback is our favorite <Icon name='smile outline' />).
              </p>
            <p>
              You may also make requests for future class or workshop topics and we&apos;ll continue to add new offerings.
              Please check the box to receive future updates.
              </p>
            <p>Thank You!</p>
          </Grid.Column>
          <Grid.Column>
            <CMForm
              state={this.state.formState}
              complete={this.state.formComplete}
              fields={this.state.formFields}
              onRecaptcha={this.onRecaptcha}
              recaptchaSiteKey={this.props.recaptchaSiteKey}
              onSubmit={this.onFormSubmit}
              successHeader='Contact Information Sent'
              successContent="Success! We received your information and will respond soon. If you don't hear from us by tomorrow night, please send an email to info@cecalamusic.com."
              errorHeader='Contact Submission Failed'
              errorContent="Oh no! We were unable to send your information. Please email us at info@cecalamusic.com."
            >
              <Form.Input
                id='name'
                label='Name'
                type='text'
                required
                disabled={this.formDisabled}
                onChange={this.handleFormFieldChange}
                error={CMForm.getError(this.state.formFields.name)}
              />

              <Form.Input
                id='email'
                label='Email'
                type='email'
                required
                disabled={this.formDisabled}
                onChange={this.handleFormFieldChange}
                error={CMForm.getError(this.state.formFields.email)}
              />

              <Form.Input
                id='subject'
                label='Subject'
                type='text'
                required
                disabled={this.formDisabled}
                onChange={this.handleFormFieldChange}
                error={CMForm.getError(this.state.formFields.subject)}
              />

              <Form.TextArea
                id='comments'
                label='Questions or Comments'
                required
                disabled={this.formDisabled}
                onChange={this.handleFormFieldChange}
                error={CMForm.getError(this.state.formFields.comments)}
              />
              
              <Form.Checkbox
                id='marketing'
                label="I want to receive news, announcements and offers."
                checked={this.state.formFields.marketing.value}
                disabled={this.formDisabled}
                onChange={this.handleFormFieldChange}
                error={CMForm.getError(this.state.formFields.marketing)}
              />

            </CMForm>
          </Grid.Column>
          <Grid.Column></Grid.Column>
        </Grid>
      </Page>
    );
  }
}