//
// footer.jsx
// Copyright 2020 Cecala Music, LLC. All rights reserved.
// Author: Brandon Cecala
//

import React from "react";

import { Menu } from 'semantic-ui-react';

export default class Footer extends React.Component {
  render() {
    return (
      <Menu fluid compact text borderless stackable size='small' className='footer center-row'>
        <Menu.Item>
          Copyright &copy; 2014-2020 Cecala Music, LLC. All rights reserved.
        </Menu.Item>
        <Menu.Item href='privacy' className='left-border'>
          Privacy Policy
        </Menu.Item>
        <Menu.Item href='terms' className='left-border'>
          Terms of Service
        </Menu.Item>
        <Menu.Item href='policy' className='left-border'>
          Studio Policy
        </Menu.Item>
      </Menu>
    );
  }
}